import React from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../store/ReduxState";
import { FineSearcher } from "./FineSearcher";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
}

type Props = StateProps & OwnProps;

/**
 *
 */
export const mapStateToProps = (_s: ReduxState): StateProps => ({
});

class HomeComp
    extends React.Component<Props, {}> {
    private resizeObserver: ResizeObserver | null = null;


    public constructor(props: Props) {
        super(props);

        this.setIframeHeight = this.setIframeHeight.bind(this);
        this.inIframe = this.inIframe.bind(this);
    }

    public componentDidMount() {
        if(this.inIframe()) {
            window.addEventListener("load", this.setIframeHeight, false);
            this.resizeObserver = new ResizeObserver(() => {
                this.setIframeHeight("resize-observer");
            });
            this.resizeObserver.observe(document.body);
            this.setIframeHeight("init");
        }
    }

    public componentWillUnmount() {
        if(this.inIframe() && this.resizeObserver) {
            window.removeEventListener("load", this.setIframeHeight, false);
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }

    public render() {
        return (
            <Mary.base.Div
                theme={{
                    padding: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
            >
                <FineSearcher asPage={this.props.asPage} />
            </Mary.base.Div>
        );
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private setIframeHeight(event: any) {
        const height = document.body.scrollHeight;
        console.log(height, "height", event);
        window.parent.postMessage(
            {
                type: "claude:fine-finder:height-changed",
                data: {
                    height: height,
                },
            },
            "*",
        );
    }
}

export const Home = connect(
    mapStateToProps,
    undefined,
)(HomeComp);
